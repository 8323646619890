#sd-container {
  position: fixed;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
}

.arrow {
  position: relative;
  width: 42px;
  height: 16px;
  opacity: 0;
  transition: transform 0.4s ease; /* Smooth rotation */

  &::before,
  &::after {
    content: "";
    width: 21px;
    height: 2px;
    background-color: #fff;
    border-radius: 2px;
    display: inline-block;
  }

  &::before {
    transform: rotate(45deg) translateX(25%);
  }

  &::after {
    transform: rotate(-45deg) translateX(-25%);
  }

  &:nth-child(1) {
    top: -50px;
    opacity: 1;
    animation: arrow2 1s ease 0s infinite;
  }

  &:nth-child(2) {
    top: -25px;
    animation: arrow1 1s ease 0.25s infinite;
  }
}

/* Persistent transformation for reverse */
.reverse {
  transform: rotate(180deg); /* Rotate arrow upwards */
  transform-origin: center;
}

@keyframes arrow1 {
  from {
    opacity: 0;
    top: -25px;
  }
  to {
    opacity: 0;
    top: 0px;
  }
  50% {
    opacity: 1;
  }
}

@keyframes arrow2 {
  from {
    opacity: 0;
    top: -35px;
  }
  to {
    opacity: 0;
    top: -10px;
  }
  50% {
    opacity: 1;
  }
}
