.cursor-container {
  position: fixed;
  pointer-events: none; /* Prevent cursor from interfering with other elements */
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 99; /* Ensure it's on top of everything else */
}

.cursor-outer {
  position: absolute;
  width: 17px; /* Outer circle size */
  height: 17px; /* Outer circle size */
  border-radius: 50%;
  background: #fff; /* White background with some transparency */
  mix-blend-mode: difference; /* Blend mode effect */
  transform: translate(-50%, -50%);
  pointer-events: none; /* Make sure it doesn't block any pointer events */
  transition: background 0.3s; /* Smooth color transition */
  pointer-events: none;
}

.cursor-inner {
  position: absolute;
  width: 13px; /* Inner circle size */
  height: 13px; /* Inner circle size */
  border-radius: 50%;
  background: #000; /* Black background */
  transform: translate(-50%, -50%);
  pointer-events: none; /* Make sure it doesn't block any pointer events */
}