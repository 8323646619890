body {
  margin: 0;
  cursor: none;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Scroll bar for Other Browsers */
body::-webkit-scrollbar-track {
  border: 1px solid black;
  background-color: #f5f5f5;
}

body::-webkit-scrollbar {
  width: 10px;
  background-color: rgb(245, 245, 245);
}

body::-webkit-scrollbar-thumb {
  background-color: rgb(48, 48, 46);
}

/* Scroll-bar for Firefox */
:root {
  scrollbar-color: rgb(158, 158, 158) rgb(48, 48, 46) !important;
  scrollbar-width: thin !important;
}
